import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_NewFeedback = _resolveComponent("NewFeedback")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createElementVNode("div", null, [
      _createVNode(_component_router_link, { to: _ctx.feedbackRoute }, {
        default: _withCtx(() => [
          _createElementVNode("button", {
            id: "backButton",
            class: "btn yellow-styled_button",
            disabled: _ctx.isDBSendingFeedback
          }, [
            _createTextVNode(" Back "),
            _createElementVNode("i", {
              class: _normalizeClass({'fas fa-arrow-circle-left': true, 'disabled': _ctx.isDBSendingFeedback})
            }, null, 2)
          ], 8, _hoisted_1)
        ]),
        _: 1
      }, 8, ["to"])
    ]),
    _createVNode(_component_NewFeedback)
  ]))
}