<template>
  <div class="vflex">
    <!-- header section strats -->
    <header>
      <div
        class="hflex hflex-centered"
      >
        <router-link
          to="/"
          class="navbar-brand"
        >
          <img
            src="/images/Strype-logo-name-64-2x.png"
            alt="Strype"
            height="64"
          >
        </router-link>

        <div
          class="news-banner"
        >
          <span><!--a
            href="/dev/"
            target="_blank"
          >
            Have a peek at the <em>development</em> version
          </a-->
            Meet us in Munich for <a
              href="https://www.wipsce.org/2024/"
              target="_blank"
            >WiPCSE 2024</a> and the <a
              href="https://www.edu.sot.tum.de/ddi/fuer-lehrkraefte/iltb-2024/"
              target="_blank"
            >Bavarian CS teachers day</a>!
          </span>
        </div>
      </div>
    </header>
    <!-- end header section -->

    <!-- dynamic slider sections -->
    <div :class="{'flex-grow':true, 'coloured-dynamic-section': isFeedbackRelatedRoute()}">
      <router-view />
    </div>
    <!-- end slider section -->

    <!-- footer section -->
    <footer id="footer">
      <table>
        <tr>
          <td>
            <h4>
              Reach at..
            </h4>
            <table id="contactTable">
              <tr>
                <td>
                  <i
                    class="fa fa-map-marker-alt"
                  />
                </td>
                <td>
                  <span>Department of Informatics<br>Bush House, 30 Aldwych<br>London<br>WC2B 4BG</span>
                </td>
              </tr>
              <tr>
                <td>
                  <i
                    class="fa fa-envelope"
                  />
                </td>
                <td>
                  <span>support@bluej.org</span>
                </td>
              </tr>
              <tr>
                <td><i class="fas fa-user-secret" /></td>
                <td>
                  <router-link
                    :to="policyRoute"
                  >
                    <span>Privacy policy</span>
                  </router-link>
                </td>
              </tr>
              <tr v-if="false && !isFeedbackRelatedRoute()">
                <td>
                  <router-link
                    :to="feedbackRoute"
                  >
                    <i class="fas fa-comment-dots" />
                  </router-link>
                </td>
                <td>
                  <router-link
                    :to="feedbackRoute"
                  >
                    <span>Feedback</span>
                  </router-link>
                </td>
              </tr>
            </table>
          </td>
          <td>
            <div>
              <div class="vflex vflex-centered">
                <a
                  href="https://www.kcl.ac.uk/"
                  target="_blank"
                >
                  <img
                    src="/images/kcl-logo.svg"
                    :width="(isScreenPortrait) ? 100 : 200"
                    alt="King's College London"
                  >
                </a>
                <span>&copy; <span>{{ displayedYear }}</span> K-PET Group - King's College London</span>
              </div>
            </div>
          </td>
          <td id="strypeHistoryLinkTD">
            <router-link
              v-if="!$route.path.startsWith(strypeHistoryRoute)"
              :to="strypeHistoryRoute"
            >
              <span>Strype release history</span>
            </router-link>
          </td>
        </tr>
      </table>
    </footer>
  <!-- footer section -->
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import { feedbacksRoutePath, newFeedbackRoutePath, policyRoutePath, strypeHistoryRoutePath } from "./router/router"
import store from "./store/store"

export default defineComponent({
    name: "App",
    store,

    computed: {
        displayedYear (): number {
            const currentDate = new Date()
            return currentDate.getFullYear()
        },

        policyRoute (): string {
            return policyRoutePath
        },

        feedbackRoute (): string {
            return feedbacksRoutePath
        },

        strypeHistoryRoute (): string {
            return strypeHistoryRoutePath
        },

        isScreenPortrait (): boolean {
            return store.getters.getIsScreenPortrait()
        },
    },

    methods: {
        isFeedbackRelatedRoute (): boolean {
            // This method returns true if we are on the feedback route or the new feedback route
            return this.$route.path.startsWith(this.feedbackRoute) || this.$route.path.startsWith(newFeedbackRoutePath)
        },
    },
})
</script>

<style>
#app {
    min-height: 100%;
    height: 100%;
}

#app > div:first-child {
    height:100%;
    min-height: 100%;
}

#app h1 {
    font-size: 2.5rem;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 15px;
}

.portraitH1 {
    font-size: 2rem !important;
}

#app h2 {
    font-size: 2rem;
    font-weight: bold;
    text-transform: uppercase;
}

.portraitH2{
    font-size: 1.75rem !important;
}

#app h4 {
    font-weight: 600;
    font-size: 1.5rem;
    margin-bottom: 20px;
}

#app h5 {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.25rem;
}

header {
    border-bottom: 1px solid #777;
    background-color: white;
    position: fixed;
    top:0;
    width: 100%;
    z-index: 99999;
}

header div.hflex {
    align-items: center;
}

header div.hflex:first-child {
    justify-content: space-between;
}

#headerControlsDiv {
    column-gap: 10px;
}

#headerControlsDiv img {
    height: 20px;
    vertical-align: top;
}

#headerControlsDiv i {
    position: relative;
    bottom: 2px;
}

#headerControlsDiv a {
    color: rgb(27,69,111);
    height: 20px;
}

#headerControlsDiv a:hover {
    color: rgb(27,69,111);
}

#headerControlsDiv a:visited {
    color: rgb(27,69,111);
}

header,section,footer {
    padding-left: 10%;
    padding-right: 10%;
}

.news-banner {
  margin-right: 100px;
}

.news-banner > span {
    color: #FF0002;
}

.news-banner a {
    color: #FF0002;
    text-decoration: underline;
}

section, footer {
    width:100%;
    padding-top: 100px;
}

.coloured-dynamic-section {
    background-color: rgb(88, 137, 185);
}

footer {
    background: rgb(27,69,111);
    color:white;
    padding-top: 50px;
}

footer > table {
    width: 100%;
}

footer > table > tr > td {
    width:30%;
    vertical-align: bottom;
}

#strypeHistoryLinkTD {
    text-align: center;
}

#contactTable td:first-child {
    padding-right: 5px;
    text-align: center;
}

#contactTable td {
    vertical-align: text-top;
}

footer a {
    color: white;
}

footer a:visited:hover {
    color: white;
}

/** App-scoped classes **/
html, body {
    min-height: 100%;
    height: 100%;
}

body {
    font-family: 'Nunito', sans-serif !important;
}

.hflex {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
}

.hflex-centered {
    justify-content: space-between;
}
.hflex-center-item {
    justify-content: center;
}

.hflex-right {
    justify-content: flex-end;
}

.vflex {
    display: flex;
    flex-flow: column;
}

.vflex-centered {
    align-items: center;
}

.flex-grow {
    flex-grow: 2;
}

.app-captcha {
    float: right;
}

.styled-input-text {
    border-radius: 5px;
    border: 0.5px solid lightgray;
    outline: none;
    background-color: rgba(255, 255, 255, 0.6) !important;
    padding-left:2px;
}

.styled-input-text-clear {
    border: none;
    outline: none;
    background-color: transparent !important;
    padding-left:2px;
}

.styled-input-text-fullW {
    width: 100%;
}

.naked-input-text {
    background-color: transparent;
    border: none;
    outline: none;
}

.input-error {
    border: 0.5px solid red;
}

.red-styled-button {
    border-radius: 5px;
    border: none;
    background-color: rgb(230, 55, 55);
    color:white;
}

.red-styled-button:hover {
    background-color: rgb(216, 65, 65) !important;
    color:white;
}

.blue-styled-button {
    border-radius: 5px;
    border: none;
    background-color: rgb(27,69,111);
    color:white;
}

.blue-styled-button:hover {
    background-color: rgb(20,56,87) !important;
    color:white !important;
}

.blue-styled-button-outline {
    border-radius: 5px;
    border: 1px solid rgb(27,69,111);
    color: rgb(27,69,111)
}

.blue-styled-button-outline:hover {
    background-color: rgb(27,69,111) !important;
    color:white !important;
    border: 1px solid transparent !important;
}

.yellow-styled_button {
    border-radius: 5px;
    border: none;
    background-color: rgb(245,200,34);
    color:white;
}

.yellow-styled_button:hover {
    background-color: rgb(205, 170, 29) !important;
    color:white !important;
}

.yellow-styled_button-outline {
    border-radius: 5px;
    border: 1px solid rgb(245,200,34);
    color: rgb(245,200,34)
}

.yellow-styled_button-outline:hover {
    background-color: rgb(245,200,34) !important;
    color:white !important;
    border: 1px solid transparent !important;
}

.red-styled_button {
  border-radius: 5px;
  border: none;
  background-color: rgb(245,100,34);
  color:white;
}

.red-styled_button:hover {
  background-color: rgb(205, 70, 29) !important;
  color:white !important;
}

.red-styled_button-outline {
  border-radius: 5px;
  border: 1px solid rgb(245,100,34);
  color: rgb(245,200,34)
}

.red-styled_button-outline:hover {
  background-color: rgb(245,100,34) !important;
  color:white !important;
  border: 1px solid transparent !important;
}

.styled-button-clear {
    border-radius: 5px;
    border: none;
}

.hr-divider {
    border-color:#777;
    margin: 50px 0px;
    width: 100%;
}
/** end of App-scopped classes **/
</style>
