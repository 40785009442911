import { getFeedbackVoteId, getFeedbackVoteIdsFromWebStorage, saveFeedbackVoteIdInWebStorage } from "@/helpers/feedback"
import { DBConsts, DBError, DBOperationType, DBOperation, FeedbackItem, FeedbackReply, FeedbackStatus, FeedbackFilterData, DBFeedbackQueryResponse } from "@/helpers/types"
import axios from "axios"
import { createStore } from "vuex"

export default createStore({
    state: {
        isScreenPortrait: false, // needed to adapt some content to portrait mode screens cf main.ts for event handling
        dbConsts: {
            serverhost: (process.env.NODE_ENV === "production") ? "https://strype.org/backend/" : "http://localhost:3200/backend/", // URL differs between dev and production modes
            extraShortTextMax: 30,
            shortTextMax: 255,
            longTextMax: 3000,
            itemsPerLoad: 10,
        } as DBConsts,
        fetchFeedbackFromDB: true, // this flag indicates if the feedback items are retrieved from DB (default) or only relying on the state
        dbOperation: { type: DBOperationType.None } as DBOperation, // this enumn flag indicates what operation is in process to retrieve data from the DB
        dbError: { flag: false } as DBError, // a flag and optional message to indicate that the DB has returned an error
        feedbackItems: [] as FeedbackItem[],
        feedbackTotalNber: 0,
        hasPendingChanges: false,
        feedbackVoted: getFeedbackVoteIdsFromWebStorage(), // used for reactivity with voting
    },

    getters: {
        getIsScreenPortrait: (state) => (): boolean => {
            return state.isScreenPortrait
        },

        getDBConsts: (state) => (): DBConsts => {
            return state.dbConsts
        },

        getFetchFeedbackFromDB: (state) => (): boolean => {
            return state.fetchFeedbackFromDB
        },

        getDBOperation: (state) => (): DBOperation => {
            return state.dbOperation
        },

        getDBError: (state) => (): DBError => {
            return state.dbError
        },

        getFeedbackTotalNber: (state) => (): number => {
            return state.feedbackTotalNber
        },

        getFeedbackItems: (state) => (): FeedbackItem[] => {
            return state.feedbackItems
        },

        getHasPendingChanges: (state) => (): boolean => {
            return state.hasPendingChanges
        },

        getFeedbackVote: (state) => (feedbackId: number, feedbackReplyId: number): number => {
            const feedbackItem = state.feedbackItems.find((feedback) => feedback.id === feedbackId)
            if (feedbackItem) {
                if (feedbackReplyId > 0) {
                    return feedbackItem.comments.find((reply) => reply.id === feedbackReplyId)?.votes ?? 0
                }
                else {
                    return feedbackItem.votes
                }
            }
            return 0
        },

        getIsFeedbackVotedUp: (state) => (feedbackId: number, feedbackReplyId: number): boolean => {
            const feedbackVoteId = getFeedbackVoteId(feedbackId, feedbackReplyId)
            return state.feedbackVoted.includes(feedbackVoteId)
        },

        getCaptchaKey: () => (): string => {
            // The key to use for captcha, depending on being testing or production mode.
            // This does not use the state at all, but since the method is required in different places, they key is obtained here.
            return (process.env.NODE_ENV === "production") ? "6Ld-u2YeAAAAAJPKUfZDTIwP1oz__fj39rgJe9Rm" : "6Ldjmg4eAAAAABUqNaJizxnxiyeFXzJARFuFHcGN"
        },
    },

    mutations: {
        setIsScreenPortrait (state, portrait: boolean) {
            state.isScreenPortrait = portrait
        },

        setFeedbacks (state, feedbackFilterData?: FeedbackFilterData) {
            // The case when we need to make a initial retrieve of the feedbacks (here so it can resused)
            // We can make a query -- we set the flag that a query is in process for the UI to be aware of a pending process
            // and clear the error flag
            // Depending on the feedbackFilterData, we either just get all feedback or get some filtered feedback
            const hasFilteredData = (feedbackFilterData !== undefined && Object.values(feedbackFilterData).some((value) => value !== undefined))
            state.dbOperation = { type: (hasFilteredData) ? DBOperationType.FilteredFeedbacks : DBOperationType.Feedbacks }
            state.dbError = { flag: false, operation: DBOperationType.None }
            const dbConsts = state.dbConsts
            axios.post(dbConsts.serverhost + ((hasFilteredData) ? "search" : ""), { limit: dbConsts.itemsPerLoad, ...feedbackFilterData })
                .then((response) => {
                    // notes: 1) the comments are not retrieved from the DB with the feedbacks, so we make sure the property is set with a value ([])
                    //        2) the front end data model expects tags in an array --> we parse what we got in the DB
                    const dbResponse = response.data as DBFeedbackQueryResponse
                    const feedbackTotalNumber = dbResponse.total_entries[0].number
                    const feedbackItems = dbResponse.feedback
                    for (const feedback of feedbackItems) {
                        feedback.comments = []
                        feedback.tags = ((feedback.tags as unknown) as string).split(",")
                    }
                    state.feedbackTotalNber = feedbackTotalNumber
                    state.feedbackItems = feedbackItems
                })
                .catch((error) => {
                    state.dbError = { flag: true, error: error, operation: (hasFilteredData) ? DBOperationType.FilteredFeedbacks : DBOperationType.Feedbacks }
                })
                .finally(() => {
                    state.dbOperation = { type: DBOperationType.None }
                })
        },

        setFeedbackTotalNber (state, total: number) {
            state.feedbackTotalNber = total
        },

        setFetchFeedbackFromDB (state, flagValue: boolean) {
            state.fetchFeedbackFromDB = flagValue
        },

        setDBOperation (state, operation: DBOperation) {
            state.dbOperation = operation
        },

        setDBError (state, payload: DBError) {
            state.dbError = payload
        },

        setMoreFeedbacks (state, feedbacks: FeedbackItem[]) {
            state.feedbackItems.push(...feedbacks)
        },

        setNewFeedback (state, payload: { title: string, content: string, author: string, tags: string[] }) {
            // We send the query to the DB and  update the reply within the store for reactivity and avoiding to reload all the feedbacks (and show the new item on top)
            // note: the date set here is for the reactive change - the DB will set the date itself anyway (they should only differ by seconds then);
            //       for id and comments and num_comments properties: we don't set them here, one will be updated from the DB response,
            //                                                        the others aren't a DB field so we set it later to default values
            const newFeedback = {
                title: payload.title,
                content: payload.content,
                tags: payload.tags,
                author: payload.author,
                datetime: new Date(Date.now()),
                votes: 0,
                status: FeedbackStatus.New,
            } as FeedbackItem
            // We set the flag that a query is in process for the UI to be aware of a pending process
            // and clear the error flag
            state.dbOperation = { type: DBOperationType.NewFeedback }
            state.dbError.flag = false
            // we need to join the tags before sending the object to DB
            axios.post(state.dbConsts.serverhost + "add", { ...newFeedback, tags: newFeedback.tags.join(",") })
                .then((response) => {
                    // update the properties which were left blank
                    newFeedback.id = response.data.id as number
                    newFeedback.comments = []
                    newFeedback.num_comments = 0
                    // update state for reactivity (at first position, we want it to show first)
                    state.feedbackItems = [newFeedback, ...state.feedbackItems]
                })
                .catch((error) => {
                    state.dbError = { flag: true, error: error, operation: DBOperationType.NewFeedback }
                })
                .finally(() => {
                    state.dbOperation = { type: DBOperationType.None }
                })
        },

        setFeedbackComments (state, payload: { feedbackItemId: number, feedbackItemComments: FeedbackReply[] }) {
            const feedbackItemIndex = state.feedbackItems.findIndex((feedback) => feedback.id === payload.feedbackItemId)
            if (feedbackItemIndex > -1) {
                state.feedbackItems[feedbackItemIndex].comments = payload.feedbackItemComments
            }
        },

        setNewFeedbackReply (state, payload: { feedbackItemId: number, content: string, author: string }) {
            // We send the query to the DB and  update the reply within the store for reactivity and avoiding to reload all the feedbacks
            // note: the date set here is for the reactive change - the DB will set the date itself anyway (they should only differ by seconds then)
            const feedbackItem = state.feedbackItems.find((feedback) => feedback.id === payload.feedbackItemId)
            if (feedbackItem) {
                const newReply = { author: payload.author, content: payload.content, datetime: new Date(Date.now()), votes: 0 } as FeedbackReply
                // We set the flag that a query is in process for the UI to be aware of a pending process
                // and clear the error flag
                state.dbOperation = { type: DBOperationType.NewFeedbackComment, payload: [payload.feedbackItemId] }
                state.dbError.flag = false
                axios.post(state.dbConsts.serverhost + "addComment", { ...newReply, feedback_id: feedbackItem.id })
                    .then((response) => {
                        newReply.id = response.data.id as number
                        // update state for reactivity
                        feedbackItem.comments.push(newReply)
                        feedbackItem.num_comments += 1
                    })
                    .catch((error) => {
                        state.dbError = { flag: true, error: error, operation: DBOperationType.NewFeedbackComment, payload: [payload.feedbackItemId] }
                    })
                    .finally(() => {
                        state.dbOperation = { type: DBOperationType.None }
                    })
            }
        },

        setHasPendingChanges (state, hasPendingChanges: boolean) {
            state.hasPendingChanges = hasPendingChanges
        },

        setFeedbackVoteUp (state, payload: { feedbackId: number; feedbackReplyId: number; value: number }) {
            const feedbackItemIndex = state.feedbackItems.findIndex((feedback) => feedback.id === payload.feedbackId)
            const feedbackReplyIndex = (feedbackItemIndex === -1) ? -1 : state.feedbackItems[feedbackItemIndex].comments.findIndex((reply) => reply.id === payload.feedbackReplyId)
            if (feedbackItemIndex > -1) {
                // We first try to save the vote in the DB - note that we don't update the vote from here: we only have it increased by 1 in the DB
                // (that is to avoid a discripancy between the value shown to the user, and a potiental newer value in the DB)
                state.dbOperation = { type: DBOperationType.AddVote, payload: [payload.feedbackId, payload.feedbackReplyId] }
                state.dbError.flag = false
                axios.post(state.dbConsts.serverhost + "vote",
                    (payload.feedbackReplyId > 0) ? { feedback_id: payload.feedbackId, comment_id: payload.feedbackReplyId } : { feedback_id: payload.feedbackId })
                    .then(() => {
                        // if the DB operation succeeded, we can proceed to the reactive change and save the flag for this vote in the web storage
                        const feedbackVoteId = getFeedbackVoteId(payload.feedbackId, payload.feedbackReplyId)
                        if (payload.feedbackReplyId > 0) {
                            if (feedbackReplyIndex > -1) {
                                state.feedbackItems[feedbackItemIndex].comments[feedbackReplyIndex].votes = payload.value
                                state.feedbackVoted.push(feedbackVoteId)
                            }
                        }
                        else {
                            state.feedbackItems[feedbackItemIndex].votes = payload.value
                            state.feedbackVoted.push(feedbackVoteId)
                        }
                        saveFeedbackVoteIdInWebStorage(feedbackVoteId)
                    })
                    .catch((error) => {
                        state.dbError = { flag: true, error: error, operation: DBOperationType.AddVote, payload: [payload.feedbackId, payload.feedbackReplyId] }
                    })
                    .finally(() => {
                        state.dbOperation = { type: DBOperationType.None }
                    })
            }
        },
    },

    actions: {
    },
})
