<template>
  <div id="homeContainerDiv">
    <!-- Strype editor access and main presentation section -->
    <section id="homeMainSection">
      <div class="hflex hflex-center-item">
        <div>
          <h1 :class="{portraitH1: isScreenPortrait}">
            Strype: a frame-based approach to Python
          </h1>
          <p>
            A Python editor that combines the strengths of blocks and text programming: write Python code in your browser with structured support.
          </p>
          <div
            id="goCodeDiv"
            class="hflex hflex-center-item"
          >
            <div
              type="button"
              :class="{'vflex vflex-centered':true, 'gocode-large-btn-div': !isScreenPortrait}"
              onclick="openEditor();"
            >
              <img
                v-if="!isScreenPortrait"
                src="/images/editor_snapshot.png"
                alt="Strype editor"
                width="316"
              >

              <button :class="{'btn red-styled_button': true, 'gocode-btn-portrait': isScreenPortrait}">
                Start coding now!
              </button>
              <p>
                Or code for <a href="/microbit">micro:bit (v1 &amp; v2)</a>.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- end Strype editor access and main presentation section -->

    <section id="homeFeaturesSection" style="padding-top: 30px">
      <h2>Key features</h2>
      <p class="feature-explanation">
        Strype is a frame-based editor for Python.  Frames look like this:
      </p>
      <div class="fake-code">
        <div class="fake-while">
          <span class="keyword">while</span> <span class="slot">programming_in_Strype()</span><span class="keyword">:</span>
          <div class="fake-body">
            <div class="fake-method-call">freed_from_indentation()</div>
            <div class="fake-method-call">reduced_syntax_errors()</div>
          </div>
        </div>
      </div>
      <p class="feature-explanation">In Strype all code is a frame: a draggable rectangle.  The code above has three frames: the while, and the two function calls.  Where a frame sits in the structure determines its indentation, so you never have to type or delete spaces to manage indentation.  Brackets are always inserted as pairs, so it is impossible to have mismatched brackets in Strype.</p>
      <div class="fake-code">
        <div class="fake-function">
          <span class="keyword">def</span> <span class="slot-identifier">running_Strype</span><span class="keyword"> ( ) :</span>
          <div class="fake-body">
            <div class="fake-method-call">features <span class="keyword">=</span> [<span class="string">“Runs in the browser”</span>]</div>
            <div class="fake-method-call">features <span class="keyword">=</span> [<span class="string">“Console I/O”</span>]</div>
            <div class="fake-method-call">features <span class="keyword">=</span> [<span class="string">“Turtle graphics”</span>]</div>
            <div class="fake-method-call">features <span class="keyword">=</span> [<span class="string">“Micro:bit”</span>]</div>
            <div class="fake-method-call"><span class="keyword">return</span> features</div>
          </div>
        </div>
      </div>
      <p class="feature-explanation">Strype runs in your browser, without needing to download or install anything.  In the browser there is console support (for input() and print()), plus Turtle graphics.  There is also support for writing microPython code for the micro:bit, with support for running the code direct from the browser.</p>
      <div class="fake-code">
        <div class="fake-if">
          <span class="keyword">if</span> <span class="slot">wanting_more()</span><span class="keyword">:</span>
          <div class="fake-body">
            <div class="fake-method-call">import_export_Python()</div>
            <div class="fake-method-call">load_save_locally_or_cloud()</div>
          </div>
        </div>
      </div>
      <p class="feature-explanation">Strype allows you to import your existing Python files, or export to Python so you can use existing programs or transfer your code to other tools.  (Currently, Strype supports many Python features, but object-orientation is not yet supported.)  You can load and save your Strype files from/to your local drive, or from/to Google Drive.</p>
    </section>

    <!-- News/Infos section (other apps) -->
    <section id="homeNewsSection" style="padding-top: 50px">
      <div class="vflex">
        <h2 :class="{portraitH2: isScreenPortrait}" style="margin-bottom: 50px">
          Strype In Under 3 Minutes
        </h2>
        <div class="hflex">
          <video
            :class="{'home-news-media-portrait': isScreenPortrait}"
            controls
          >
            <source
              src="video/strype_demo.mp4"
              type="video/mp4"
            >
          </video>
        </div>
      </div>
    </section>
    <!-- end about section -->

    <!-- department section -->
    <section class="vflex vflex-centered">
      <h2 :class="{portraitH2: isScreenPortrait}">
        About Us
      </h2>
      <p>
        Strype was developed by the K-PET group at King's College London, under the supervision of Professor Michael Kölling.  We also make several other successful programming tools:
      </p>
      <div
        id="departmentOtherAppsDiv"
        class="hflex hflex-centered"
      >
        <div class="vflex vflex-centered">
          <div class="department-otherapps-logo">
            <div>
              <a
                href="https://www.bluej.org/"
                target="_blank"
              >
                <img
                  src="images/bluej.png"
                  alt=""
                >
              </a>
            </div>
          </div>
          <h5>
            BlueJ
          </h5><p :class="{'department-otherapps-p-portrait': isScreenPortrait}">
            BlueJ is an educational integrated development environment (IDE) for Java which employs the "object
            first" approach to learning of object-oriented programming.
            Widely used by many institutions around the world.
          </p>
        </div>
        <div class="vflex vflex-centered">
          <div class="department-otherapps-logo">
            <div>
              <a
                href="https://www.greenfoot.org/"
                target="_blank"
              >
                <img
                  src="images/greenfoot.png"
                  alt=""
                >
              </a>
            </div>
          </div>
          <h5>
            Greenfoot
          </h5> <p :class="{'department-otherapps-p-portrait': isScreenPortrait}">
            Greenfoot is educational software that allows easy development of two-dimensional graphical
            applications, such as simulations and interactive games, through Java programming.
          </p>
        </div>
        <div class="vflex vflex-centered">
          <div class="department-otherapps-logo">
            <div>
              <a
                href="https://www.stride-lang.net/what_is_stride/"
                target="_blank"
              >
                <img
                  src="images/stride.png"
                  alt=""
                >
              </a>
            </div>
          </div>
          <h5>
            Stride
          </h5> <p :class="{'department-otherapps-p-portrait': isScreenPortrait}">
            Stride is the first frame-based editor (similar to Strype) which allows for Java programming through
            drag and drop but supporting keyboard only interaction at the same time!
          </p>
        </div>
      </div>
    </section>
  <!-- end department section -->
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import store from "@/store/store"

export default defineComponent({
    name: "HomeView",
    store,
    computed: {
        isScreenPortrait (): boolean {
            return store.getters.getIsScreenPortrait()
        },
    },
})
</script>

<style>
#homeMainSection {
    color: white;
    background-image: url("../images/home_curve_bgnd.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    min-height: 650px;
}

#strypeLogoImg {
    margin-top: auto;
    margin-bottom: auto;
    width: 18%;
}

#goCodeDiv {
    column-gap:10px;
    align-items:stretch;
    flex-wrap: nowrap !important;
    margin: 10px auto;
}

.gocode-large-btn-div {
    border: 1px solid gainsboro;
    border-radius: 5px;
    background: white;
    padding: 20px 20px 5px 20px;
    row-gap: 10px;
}

#goCodeDiv button{
    font-size: 20px;
    font-weight: 700;
    flex-shrink: 2;
}

#goCodeDiv p {
    color: #333;
}

.gocode-btn-portrait {
    font-size: 18px !important;
    margin-bottom: 5px;
}

#goCodeDiv img {
   width: 20vw;
}

.gocode-large-btn-div > div {
    height: 100%;
    justify-content: center;
}

.fake-code {
  font-family: Source Sans Pro, sans-serif;
  font-size: 150%;
  max-width: 50rem;
  margin-left: auto;
  margin-right: auto;
  color: rgb(0, 0, 0) !important;
}

.fake-while {
  background-color: rgb(228, 214, 206) !important;
  padding-left: 1rem;
}
.fake-if {
  background-color: rgb(224, 223, 228) !important;
  padding-left: 1rem;
}
.fake-function {
  background-color: rgb(236, 236, 200) !important;
  padding-left: 1rem;
}

.fake-body {
  margin-left: 4rem;
  margin-right: 1rem;
  margin-top: 0.6rem;
  margin-bottom: 0.4rem;
  background-color: rgb(246, 242, 233) !important;
}

.fake-body, .fake-while, .fake-function, .fake-if {
  border-radius: 8px;
  border: 1px solid #b4b4b4;
  outline: none;
}

.fake-method-call {
  display: block;
  margin-left: 1rem;
}

.fake-code .keyword {
  font-weight: 600;
  color: #0221a8;
}

.fake-code .string {
  color: #060;
}

#homeFeaturesSection h2, #homeVideoSection h2, #homeAboutSection h2, #homeNewsSection h2 {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.feature-explanation {
  max-width: 40rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  font-size: 120%;
}

.features {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; /* Distribute items evenly */
  gap: 10px; /* Space between items */
}

.feature {
  flex-basis: 48%; /* Each item takes up 48% of the container's width */
  background-color: white;
  border-width: 1px;
  border-color: #1B456F;
  border-style: solid;
  padding: 20px;
  box-sizing: border-box; /* Ensure padding is included in the width */
  text-align: center;
  font-weight: bold;
}

#homeNewsSection > div {
    width:100%;
}

#homeNewsSection div.hflex {
    align-items: center;
    justify-content: center;
}

.home-news-text-container,.home-news-text-container-right, #homeNewsSection video, #homeNewsSection iframe{
    width: 80%;
}

.home-news-text-container {
    padding-left: 20px;
}

.home-news-text-container-right {
    text-align: end;
    padding-right: 20px;
}

.home-news-text-container-portrait:nth-child(even) {
    margin-top: 20px;
}

.home-news-media-portrait {
    width:80vw !important;
}

#departmentOtherAppsDiv {
    column-gap: 5px;
    width: 100%;
}

#departmentOtherAppsDiv p{
    text-align: justify;
    max-width: 23vw;
}

.department-otherapps-p-portrait {
    max-width: 80vw !important;
}

.department-otherapps-logo {
    display: inline-block;
    margin-bottom: 15px;
    margin-top: 45px;
}

.department-otherapps-logo div {
    width: 90px;
    height: 90px;
    border: 1px solid rgb(27,69,111);
    border-radius: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.department-otherapps-logo img {
    max-width: 55px;
    max-height: 55px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

</style>
